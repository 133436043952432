import { DealerRole, AdminRole } from '@/generated/typing';

export enum Section {
    ALL_SECTIONS = 'allSections',
    PRODUCT_MATCHING = 'productMatching',
    CHILDREN = 'children',
    ACCOUNT = 'account',
    ACCOUNT_UPDATE = 'updateAccount',
    ACCOUNT_SETTINGS = 'accountSettings',
    ADMIN = 'admin',
    API = 'api',
    ADMINISTRATION = 'administration',
    BILLING_INFORMATIONS = 'billingInformations',
    CLIENTS = 'clients',
    COMPANIES = 'companies',
    COMPANY = 'company',
    CONTRACTS = 'contracts',
    CONTRACTS_UPDATE = 'updateContracts',
    CONTRACTS_MAIL_TEMPLATE_UPDATE = 'updateContractsMailTemplate',
    CUSTOMER_EXTERNAL_ID = 'customer_external_id',
    CUSTOMER_GDA = 'customer_gda',
    WEBSITES_MAIL_TEMPLATE_UPDATE = 'updateWebsitesMailTemplate',
    CONTRACTS_DOCUMENT_TEMPLATES = 'constractsDocumentTemplates',
    DASHBOARD = 'dashboard',
    DASHBOARD_CLAIMS = 'dashboard_claims',
    DATA_PROVIDERS = 'dataProviders',
    STORE_IMPORT_HISTORY = 'storeImportHistory',
    STORE_INVOICES = 'storeInvoices',
    DOCUMENT_TEMPLATES = 'documentTemplates',
    MAIL_TEMPLATES = 'mailTemplates',
    PAYMENT_METHODS = 'paymentMethods',
    PRODUCTS = 'products',
    PRODUCT_CONFIGURATION = 'productConfiguration',
    PRODUCTS_CONFIGURATION = 'productsConfiguration',
    PRODUCTS_STORES = 'productsStores',
    PRODUCTS_COVERAGE = 'productsCoverage',
    PRODUCTS_CLAIMS = 'productsClaims',
    PROVIDERS = 'providers',
    SALES_CHANNELS = 'salesChannels',
    SERVICES = 'services',
    SERVICES_ACCOUNTS = 'servicesAccounts',
    STORE = 'store',
    STORES = 'stores',
    SUBSCRIBE = 'subscribe',
    SUBSCRIPTIONS = 'subscriptions',
    SUBSCRIPTION_EXTERNAL_ID = 'subscriptionExternalId',
    SUBSCRIPTION_PAYMENT_MEAN = 'subscriptionPaymentMean',
    DEALERS = 'dealers',
    ADMINS = 'admins',
    WEBSITES = 'websites',
    UPDATE_SUBSCRIPTION_CONTRACT = 'updateSubscriptionContract',
    PROVIDER_SUBSCRIPTIONS = 'getProviderSubscriptions',
    SUBSCRIPTION_PROCEDURES = 'subscriptionProcedures',
    DUPLICATE_STORE_CONFIG = 'duplicateStoreConfig',
    DUPLICATE = 'duplicate',
    DUPLICATE_CLAIM_FIELDS = 'duplicateClaimFields',
    CLAIMS = 'claims',
    STORE_CLAIMS = 'storeClaims',
    WEBHOOKS = 'webhooks',
    EXPORT_CONFIG = 'exportConfig',
    CHANGE_SUBSCRIPTION_CONTRACT = 'changeSubscriptionContract',
    COMPENSATION = 'compensation',
    CLAIM_PROPERTY_MAPPINGS = 'claimFieldsMapping',
    IMPORT_HISTORY = 'importHistory',
}

export enum Feature {
    ALL_FEATURES = 'allFeatures',
    DELETE_CONTRACT = 'deleteContract',
    UPDATE_SUBSCRIPTION_SERVICE_FIELDS = 'updateSubscriptionServiceFields',
    UPDATE_SUBSCRIPTION_PAYMENT_METHOD = 'updateSubscriptionPaymentMethod',
    CHANGE_SUBSCRIPTION_PAYMENT_METHOD = 'changeSubscriptionPaymentMethod',
    VOID_SUBSCRIPTION = 'voidSubscription',
    REFUND_SUBSCRIPTION = 'refundSubscription',
    UPDATE_DEALER_ROLE = 'updateDealerRole',
    DELETE_DEALER_FROM_STORE = 'deleteDealerFromStore',
    DELETE_ADMIN = 'deleteAdmin',
    UPDATE_ADMIN_ROLE = 'updateAdminRole',
    UPDATE_AUTO_MAIL_BILLING_INFORMATIONS = 'updateAutoMailBillingInformations',
    UPDATE_AUTO_GENERATION_BILLING_INFORMATIONS = 'updateAutoGenerationBillingInformations',
    OVERRIDE_COMPANY_NAME = 'overrideCompanyName',
    UPDATE_AUTO_OPTIMISATION_BILLING_INFORMATIONS = 'updateAutoOptimisationBillingInformations',
    GET_SUBSCRIPTION_PAYMENT_MEAN = 'getSubscriptionPaymentMean',
    UPDATE_SUBSCRIPTION_CONTRACT = 'updateSubscriptionContract',
    CREATE_DATA_PROVIDER = 'createDataProvider',
    CREATE_SERVICE = 'createService',
    CREATE_PRODUCT = 'createProduct',
    CREATE_SERVICE_ACCOUNT = 'createServiceAccount',
    CREATE_STORE = 'createStore',
    CREATE_DOCUMENT_TEMPLATE = 'createDocumentTemplate',
    CREATE_MAIL_TEMPLATE = 'createMailTemplate',
    CREATE_PROVIDER = 'createProvider',
    CREATE_CONTRACT = 'createContract',
    IBAN_BILLING_INFORMATIONS = 'ibanBillingInformations',
    CANCEL_SUBSCRIPTION_CONTRACT = 'cancelSubscriptionContract',
    DELETE_STORE_INVOICE = 'deleteStoreInvoices',
    WEBHOOKS = 'webhooks',
    UPDATE_STORE_INVOICE_STATUS = 'updateStoreInvoiceStatus',
    GET_ALL_INVOICES = 'getAllInvoices',
    UPDATE_BENEFICIARIES = 'updateBeneficiaries',
    SET_SUBSCRIPTION_EXTERNAL_ID = 'setSubscriptionExternalId',
    EXPORT_SUBSCRIPTIONS = 'exportSubscriptions',
    GENERATE_MEMBERSHIP_AGREEMENT = 'generateMembershipAgreement',
    SEND_DOCUMENT = 'sendDocument',
    UPDATE_CUSTOMER = 'updateCustomer',
    DELETE_CLAIM = 'deleteClaim',
}

export type AccessMap = { [key in Section | Feature]?: boolean };
export type RolesMap = { [key in DealerRole | AdminRole]?: AccessMap };
